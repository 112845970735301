export enum ModalIds {
    CONFIRM = 'confirm',
    PROFILE = 'profile',
    FLOAT_RANGE = 'float-range',
    INVENTORY_ITEM = 'inventory-item-modal',
    INVENTORY_PRIVATE = 'inventory-private-modal',
    ADVANCED_SEARCH = 'advanced-search-modal',
    BLOG_CATEGORIES = 'blog-categories-modal',
    COMPARISON_SORT = 'comparison_sort',
    MOBILE_SEARCH = 'mobile-search',
    MOBILE_NAV = 'mobile-nav',
    BUY_TOKENS = 'buy-tokens',
    CANCEL_SUBSCRIPTION = 'cancel-subscription',

    ADDONS_MARKET_ORDER_ANALYTICS = 'market_order_analytics',
    ADDONS_PRICE_RECOMMENDATIONS = 'price_recommendations',
    ADDONS_STEAM_ANALYTICS = 'steam_analytics',
    ADDONS_MIN_PRICES = 'min_prices',
    ADDONS_MAX_ORDERS = 'max_orders',
    ADDONS_ADDITIONAL_API_REQUESTS = 'additional_api_requests',
}
